<script lang="ts" context="module">
	export enum ButtonSize {
		Large,
		Small,
		TableRow,
		Default
	}
</script>

<script lang="ts">
	export let href: string = '';
	export let submit: boolean = false;
	export let disabled: boolean = false;
	export let primary: boolean = false;
	export let fit: boolean = false;
	export let size: ButtonSize = ButtonSize.Default;
	export let icon: string = '';
	export let type: 'button' | 'submit' | 'reset' | null | undefined = undefined;
	export let formaction: string | undefined = undefined;

	if (!type && submit) {
		type = 'submit';
	}

	function onClick() {
		if (href) {
			window.location.href = href;
		}
	}
</script>

<button
	on:click={onClick}
	target="_blank"
	class="button {icon}"
	{type}
	class:submit
	class:disabled
	class:primary
	class:fit
	class:small={size === ButtonSize.Small}
	class:large={size === ButtonSize.Large}
	class:table-row={size === ButtonSize.TableRow}
	class:icon={icon !== ''}
	class:solid={icon !== ''}
	{formaction}
>
	<slot />
</button>

<style lang="scss">
	.button {
		&.small {
			font-size: 0.4em !important;
		}
	}
</style>
